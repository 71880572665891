
import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";

import { MessageBox } from "element-ui";
import enumFilter from "@/mixins/filters/enums";

import {CmsContentDto, ProjectSeriesDto} from "@/api/appService";
import EditDepartment from "./edit.vue";
import { createTableTree } from "@/utils/tree";

@Component({
  name: "ProjectSeriesList",
  components:{
    EditDepartment
  }
})
export default class ProjectSeriesList extends Vue {
  @Ref() editForm!: EditDepartment;

  tableItems: ProjectSeriesDto[] = [];

  table = {
    listLoading:true
  } as any;

  parentId:number = 0;
  dataId:number = 0;
  async created() {
    await this.fetchData();
  }

  // 获取表数据
  async fetchData() {
    this.table.listLoading = true;
    await api.projectSeries.getAll({maxResultCount:14211}).then(res => {
      const tree = createTableTree(
        res.items!,
        "parentId",
        "id",
        null,
        "children"
      );
      this.tableItems = tree;
      this.table.listLoading = false;
    });
  }

  // 新建
  // handleCreate() {
  //   this.parentId = 0;
  //   (this.editForm as any).show = true;
  // }
  handleCreate() {
    this.$router.push({name: 'projectSeriesCreate',params: {'showType': '1'}})
  }

  handleCreateChild(row:ProjectSeriesDto){
    //this.parentId = row.id!;
    this.$router.push({name: 'projectSeriesCreate', params: {'parentId': row.id!.toString(),'showType': '0'}})
  }

  // 跳转编辑页
  handleEdit(index: number, item: ProjectSeriesDto) {
    this.$router.push({name: 'projectSeriesEdit', params: {'id': item.id!.toString(),'showType':(item.parentId??0>0)? '0':'1'}})
  }

  // 删除
  async handleDelete(index: number, row: ProjectSeriesDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.projectSeries.delete({ id: row.id }).then(res => {
        this.$message({
          type: "success",
          message: "删除成功!"
        });
        this.fetchData();
      });
    });
  }

  async handelOnSaved() {
    await this.fetchData();
  }
}
