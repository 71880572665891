
import {Component, Ref, Vue, Watch} from "vue-property-decorator";
import Tinymce from "@/components/Tinymce/index.vue";
import {ElForm} from "element-ui/types/form";
import {
  ProjectSeriesDto,
  ProjectSeriesCreateOrUpdateDto,
  AttachmentHostType, ProjectSeriesType
} from "@/api/appService";
import api from "@/api";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";
import MultipleUploadFile from "@/components/MultipleUploadFile/index.vue";
import Ueditor from "@/components/Ueditor/index.vue";
import {createNgTree, getCategoryIdList, INgNode} from "@/utils/tree";

@Component({
  components: {SimpleUploadImage, Tinymce, MultipleUploadFile,Ueditor }
})

export default class EditCmsContent extends Vue {
  @Ref() dataForm!: ElForm;

  cmsContentId?:number = 0;
  submitting:boolean = false;
  showSeriesType:boolean=false;
  form:ProjectSeriesCreateOrUpdateDto | undefined = {
    title: undefined,
    titleImagePath: undefined,
    isPublicShow: true,
    sequence: 0,
    parentId:undefined,
    description:undefined,
    seriesType:ProjectSeriesType.Internal,
    achievement:undefined,
    id: 0
  };
  showAchievement:boolean=this.form?.seriesType==ProjectSeriesType.Internal;
  activeNames:any[] = ['1','2','3']
  private selectList: any = [
    {
      label: "是",
      value: true
    },
    {
      label: "否",
      value: false
    }
  ];

  private typeList: any = [
    {
      label: "内部项目",
      value:ProjectSeriesType.Internal
    },
    {
      label: "外部项目",
      value: ProjectSeriesType.External
    }
  ];

  @Watch("form.seriesType")
  watchSeriesType(value:any) {
    this.showAchievement=value==ProjectSeriesType.Internal?true:false;
  }

  async created() {
    this.showSeriesType=this.$route.params.showType=='1'?true:false;
    this.showAchievement=this.$route.params.showType=='1'?true:false;
    if(this.$route.params.parentId){
      this.form!.parentId=Number(this.$route.params.parentId);
      await this.fetchParentDetail();
    }
    if(this.$route.params.id){
      this.cmsContentId = Number(this.$route.params.id);
      await this.fetchDetail();
    }
  }

  get hostType() {
    return AttachmentHostType.ProjectSeries;
  }

  get hostTypeFile() {
    return AttachmentHostType.ProjectSeries;
  }
  // 获取详情
  async fetchDetail() {
    await api.projectSeries.get({id:this.cmsContentId}).then(res=>{
      this.form = {...res};
      this.showAchievement=this.form.seriesType==ProjectSeriesType.Internal?true:false;
      if(
        res!.seriesType!=ProjectSeriesType.Internal
        && res!.seriesType!=ProjectSeriesType.External)
      {
        //this.form!.seriesType=ProjectSeriesType.Internal;//默认值
      }
    })
  }

  async fetchParentDetail() {
    await api.projectSeries.get({id:this.form!.parentId}).then(res=>{
      this.form!.seriesType=res.seriesType;
      this.showAchievement=this.form!.seriesType==ProjectSeriesType.Internal?true:false;
    })
  }

  // 折叠面板Collapse 事件
  handleCollapseChange(val:any) {
    console.log(val,'Collapse')
  }


  // 返回
  private cancel() {
    this.$router.back();
  }
  // 保存为草稿
  private async saveAsDraft() {
    this.save(false);
  }
  // 发布
  private async saveAndPublish() {
    this.save(true);
  }

  private async save(CmsContentStatus:boolean) {
    console.log(this.form);
    if(this.form?.seriesType!=ProjectSeriesType.Internal){
      this.form!.achievement=undefined;
    }

    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        this.submitting = true
        let fn = undefined
        if (this.form!.id) {
          fn = api.projectSeries.update

        } else {
          fn = api.projectSeries.create
        }
        await fn({ body: this.form }).then(res=>{
          this.$message({
            type: "success",
            message: "成功"
          });
          setTimeout(() => {
            this.$router.push({ name: "projectSeries" });
          }, 200);
        }).catch(()=>{
          this.submitting = false
        });
      } else {
        this.$message({
          type: 'error',
          message: '请检查表单'
        })
      }
    });
  }

  roleRule = {
    title: [
      {
        required: true,
        message: "请输入系列名称",
        trigger: "blur"
      }
    ],
    isPublicShow: [
      {
        required: true,
        message: "是否公开显示未选择",
        trigger: "blur"
      }
    ],
    sequence: [
      {
        required: true,
        message: "请输入显示顺序",
        trigger: "change"
      },
      {
        type: "number",
        message: "显示顺序必须为数字",
        trigger: "change"
      }
    ],
    description: [
      {
        required: true,
        message: "请填写内容",
        trigger: "blur"
      }
    ],
    seriesType: [
      {
        required: true,
        message: "请填写内容",
        trigger: "change"
      }
    ],

  };
}
